<template>
  <help-dialog
    header="Uploading packages with Twine"
    :example="example"
    btn-id="python-step2"
    btn-text="Show me how to upload packages"
  >
    <template v-slot:footer>
      For full details on how to build and deploy Python packages, please refer
      to <a href="https://docs.packagr.app">the docs</a>
    </template>
  </help-dialog>
</template>

<script>
import HelpDialog from "@/components/base/HelpDialog";

export default {
  components: { HelpDialog },
  props: ["url", "value", "user"],
  computed: {
    example() {
      if (this.user) {
        return `pip install twine
twine upload --repository-url ${this.url} my-package.whl

Enter your username: ${this.user.username}
Enter your password: ********
      `;
      }
      return "";
    },
  },
};
</script>
