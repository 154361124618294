var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-3",attrs:{"id":("python-" + (item.name) + "-name")}},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('div',[_c('router-link',{attrs:{"to":("/python/" + (item.id))}},[_vm._v("Edit")])],1)])]}},{key:"item.latestVersion",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(item.latestVersion))])]}},{key:"item.public",fn:function(ref){
var item = ref.item;
return [(!item.public)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("fa-check-circle")]):_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("fa-times-circle")])]}},{key:"item.totalFileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fileSize")(item.totalFileSize))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }