<template>
  <Dialog v-model="dialog" :title="header">
    <template v-slot:activator="{ on }">
      <default-button :on="on" color="primary" icon="fa-info" :id="btnId">{{
        btnText
      }}</default-button>
    </template>
    <slot name="header"></slot>
    <v-card-text>
      <pre
        class="pa-4 grey darken-4 white--text"
      ><code class="code" data-test="example">{{example}}</code></pre>
    </v-card-text>
    <v-card-text class="footer">
      <slot name="footer"></slot>
    </v-card-text>
  </Dialog>
</template>

<script>
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";
export default {
  components: { DefaultButton, Dialog },
  props: ["btnText", "btnId", "example", "header"],
  data: () => ({
    dialog: false,
  }),
};
</script>

<style scoped>
.v-application pre code.code {
  all: unset;
}
</style>
