<template>
  <twine-help-dialog :user="user" :url="url" />
</template>

<script>
import { queries } from "@/gql";
import TwineHelpDialog from "./TwineHelpDialog";
const { user } = queries;

export default {
  components: { TwineHelpDialog },
  props: ["url"],
  apollo: { user },
};
</script>
