<template>
  <v-row class="ma-4">
    <page-header>
      <template v-slot:subtitle>
        <div v-if="repositoryUrl">
          <div class="grey--text mt-3 text--darken-1">
            Repository URL:
            <span
              id="python-step1"
              class="font-weight-bold primary--text"
              data-test="python-url"
              >{{ repositoryUrl }}</span
            >
          </div>
          <twine-help-dialog-wrapper :url="repositoryUrl" />
        </div>
        <v-progress-circular
          size="24"
          indeterminate
          v-else
        ></v-progress-circular>
      </template>
    </page-header>
    <v-col cols="12">
      <v-card tile>
        <table-header title="Packages">
          <v-text-field
            solo
            dense
            class="mt-5 pr-4"
            v-model="search"
            label="Search by package name"
          >
            <v-icon slot="prepend-inner" class="pr-2" small>
              fas fa-search
            </v-icon>
          </v-text-field>
          <v-spacer />
          <default-button
            @click="$apollo.queries.packages.refetch()"
            icon="fa-sync"
            data-test="refresh-btn"
            >Refresh</default-button
          >
        </table-header>
        <v-card-text>
          <python-packages :search="search" :items="packages" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PageHeader from "@/components/widgets/PageHeader";
import DefaultButton from "@/components/widgets/DefaultButton";
import TableHeader from "@/components/widgets/TableHeader";
import PythonPackages from "@/components/tables/PythonPackages";
import { queries } from "@/gql";
import { API_HOST } from "@/config";
import { pythonSteps as steps, defaultOptions as options } from "@/tour";
import TwineHelpDialogWrapper from "../components/dialogs/TwineHelpDialogWrapper";

const { packages, account, newAccount } = queries;

export default {
  apollo: { packages, account, newAccount },
  components: {
    DefaultButton,
    TwineHelpDialogWrapper,
    TableHeader,
    PageHeader,
    PythonPackages,
  },
  methods: {
    triggerTutorial() {
      if (this.repositoryUrl) {
        const self = this;
        if (this.newAccount) {
          setTimeout(() => {
            const intro = self.$intro();
            intro.setOptions({ steps, ...options });
            intro.start();
          }, 500);
        }
      }
    },
  },
  data: () => ({
    search: "",
  }),
  watch: {
    newAccount() {
      this.triggerTutorial();
    },
    repositoryUrl() {
      this.triggerTutorial();
    },
  },
  computed: {
    repositoryUrl() {
      if (this.account) {
        return `${API_HOST}/${this.account.hashId}`;
      }
      return "";
    },
  },
};
</script>
